import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero'
import styles from '../css/template.module.css'
import Image from 'gatsby-image'
import { FaMap } from 'react-icons/fa'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Template = ({ data }) => {
    const { project, land, beschrijving: { beschrijving }, afbeeldingen } = data.contentfulProjecten;
    const [ mainImage, ...projectImages ] = afbeeldingen;

    return (
        <Layout>
            <StyledHero img={mainImage.fluid} />
            <section className={styles.template}>
                <div className={styles.center}>
                    <div className={styles.images}>
                        {
                            projectImages.map((item, index) => {
                                return <Image key={index} fluid={item.fluid} className={styles.image} alt="afbeeldingen van het project"/>
                            })
                        }
                    </div>
                    <h2>{project}</h2>
                    <div className={styles.info}>
                        <p>
                            <FaMap className={styles.icon} />
                            {land}
                        </p>
                    </div>
                    <div>
                        <p className={styles.desc}>{beschrijving}</p>
                    </div>
                    <AniLink fade to="/projecten" className="btn-primary">Terug</AniLink>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
query($slug: String!) {
  contentfulProjecten(slug: { eq: $slug }) {
    project
    land
    beschrijving {
      beschrijving
    }
    afbeeldingen {
      fluid {
        ...GatsbyContentfulFluid
      }
    }
  }
}
`
export default Template
